/**
 * .App {
 *   text-align: center;
 * }
 * 
 * .App-logo {
 *   height: 40vmin;
 *   pointer-events: none;
 * }
 * 
 *   }
 * }
 * 
 * .App-header {
 *   background-color: #282c34;
 *   min-height: 100vh;
 *   display: flex;
 *   flex-direction: column;
 *   align-items: center;
 *   justify-content: center;
 *   font-size: calc(10px + 2vmin);
 *   color: white;
 * }
 * 
 * .App-link {
 *   color: #61dafb;
 * }
 * 
 *   }
 *   to {
 *     transform: rotate(360deg);
 *   }
 * }
 *
 * @format
 * @media (prefers-reduced-motion: no-preference) { .App-logo { animation: App-logo-spin infinite 20s linear;
 * @keyframes App-logo-spin { from { transform: rotate(0deg);
 */

.fixed-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

